import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { Modal, ModalBody } from "./style";

const MessageModal = ({ active, children, color, onHide }) => {
  return (
    <>
      {active && (
        <Modal onClick={onHide}>
          <ModalBody color={color}>
            {color === "success" ? (
              <BsCheckCircle />
            ) : color === "danger" ? (
              <IoCloseSharp />
            ) : (
              <></>
            )}
            {children}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MessageModal;
