import styled from "styled-components";
import { globalColor, globalFont } from "../../utils/RepeatFunctions/Schedule";

export const TopLogo = styled.img`
  width: 120px;
  height: 80px;
`;

export const CenterContainer = styled.div`
  width: 483px;
  height: ${(props) => props.height || "450px"};
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 16px;
  ${(props) => (props.center ? "text-align: center" : "")};
`;

export const KeyIcon = styled.div`
  width: 80px;
  height: 80px;
  text-align: center;
  background: ${(props) => (props.green ? "#D1F9DD" : "#c9deff")};
  border: ${(props) =>
    props.green ? "6.5px solid #ECFDF3" : "6.5px solid #ecf3ff"};
  color: ${(props) => (props.green ? "#198C55" : "#3f8cff")};
  margin-left: 200px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 40px;
  & svg {
    height: 33px;
    width: 31px;
  }
`;

export const CardTitle = styled.p`
  font-family: ${globalFont};
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #2d3748;
  margin-top: ${(props) => (props.mt20 ? "20px" : "16px")};
  margin-bottom: 8px;
  text-align: ${(props) => props.align || "center"};
`;

export const CardText = styled.p`
  font-family: ${globalFont};
  font-size: 16px;
  line-height: 19px;
  color: #718096;
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  text-align: ${(props) => props.align || "center"};
`;

export const LabelText = styled.p`
  font-family: ${globalFont};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7d8592;
  margin-top: ${(props) => (props.mt0 ? "0px" : "24px")};
  margin-bottom: 7px;
  margin-left: 40px;
  text-align: ${(props) => props.align || "center"};
`;

export const CardInput = styled.input`
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #d8e0f0")};
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: ${(props) => (props.error ? "5px" : "4px")};
  height: 44px !important;
  width: 403px;
  margin-left: 40px;
  padding-left : 18px
  text-align: ${(props) => props.align || "center"};
  padding-left: ${(props) => props.paddingLeft && props.paddingLeft};
  &::placeholder {
    color: #718096 !important;
  }
  &::-ms-input-placeholder {
    color: #718096 !important;
  }
  margin-bottom: ${(props) => (props.mb0 ? "0px" : "24px")};
`;

export const ErrorText = styled.p`
  width: 100%;
  ${(props) => !props.block && "position:  absolute"};
  top: 46px;
  font-size: 80%;
  color: #dc3545;
  margin-left: 40px;
  display: ${(props) => (props.error ? "block" : "none")};
`;

export const AllGroup = styled.div`
  ${(props) => (props.relative ? "position : relative " : "")}
`;

export const ResetButton = styled.button`
  @import url("https://fonts.googleapis.com/css?family=Nunito Sans");

  background: ${globalColor};
  border-radius: 4px;
  width: 403px;
  height: 48px;
  border: none;
  font-family: ${globalFont};
  font-weight: 700;
  font-size: 16px;
  color: white;
  margin-left: 40px;
`;

export const BackButton = styled.div`
  margin-top: 27px;
  color: #718096;
  cursor: pointer;
  font-size: 16px;
  text-align: ${(props) => props.align || "center"};
  & svg {
    margin-right: 8px;
  }
`;

export const Modal = styled.div`
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15);
  animation: fadeIn 1s;
  pointer-events: none;

  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ModalBody = styled.div`
  color: ${({ color }) =>
    color === "success"
      ? "#198C55"
      : color === "danger"
      ? "#CC0000"
      : "#FFFFFF"};
  font-size: 16px;
  font-weight: 500;

  & svg {
    color: ${({ color }) =>
      color === "success"
        ? "#198C55"
        : color === "danger"
        ? "#CC0000"
        : "#FFFFFF"};

    margin-right: 12px;
    height: 18px;
    width: 18px;
    margin-top: -4px;
  }
`;
