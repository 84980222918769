import React, { useState, useEffect, memo } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { Select } from "antd";
import CustomSuffixIcon from "./CustomSuffixIcon";
const CreateClient = ({ clientShow, dataFetch, ClientAdd, ClientFailed }) => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const { Option } = Select;

  let history = useHistory();
  const handleClose = () => clientShow(false);
  const [externalID, setExternalID] = useState("");
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [email_id, setemailid] = useState("");
  const [phone_number, setphonenumber] = useState("");
  const [providerID, setproviderID] = useState("");
  const [providerlist, getproviderlist] = useState([]);
  const [fnError, setFnError] = useState(false);
  const [lnError, setLnError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [mailError1, setMailError1] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [provErr, setProvErr] = useState(false);
  const [mailErrMsg, setMailErrMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [idErrMsg, setIdErrMsg] = useState("");
  const [sameNumber, setSameNumber] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState(false);

  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");

  //-------------------------------------------State and Variables End -------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //
  const checkPhone = (e, mode) => {
    e.preventDefault();
    const checkPhone = new Promise((resolve, reject) => {
      handlephonechange(resolve, reject);
    });

    checkPhone
      .then((res) => {
        if (res === "break") {
          throw new Error("number already used");
        }
      })
      .then(() => {
        if (mode === "more") {
          createSaveAddMore();
        } else {
          createSave();
        }
      })
      .catch((err) => {
        console.error("number already used");
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const createSave = async () => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }
    const article = {
      fname: first_name,
      lname: last_name,
      email: email_id,
      phone: phone_number,
      external: externalID,
      prov_id: providerID,
    };

    if (first_name === "" || first_name === null) {
      setFnError(true);
      return;
    } else if (last_name === "" || last_name === null) {
      setLnError(true);
      return;
    } else if (email_id === "") {
      setMailError(true);
      setMailErrMsg("Enter Email address");
      return;
    } else if (phone_number === "") {
      setPhoneError(true);
      return;
    } else if (providerID === "" || providerID === null) {
      setProvErr(true);
      return;
    } else if (!idError) {
      setDisable(true);
      let Data_enc = Encrypt_Value(article, "vozo");
      axiosInstance
        .post("/vozo/patient", Data_enc)
        .then((response) => {
          if (typeof response.data === "number") {
            OnChangeClientAdd();
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            clientShow();
            dataFetch();
            setDisable(false);
          } else if (response.data === "External ID is already present") {
            setIdError(true);
            setIdErrMsg("External ID is already present");
            setDisable(false);
          } else {
            OnChangeClientFail();
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            setDisable(false);
          }
        })
        .catch((err) => {
          setIdError(true);
          setIdErrMsg("External ID is already present");
          setDisable(false);
        });
    }
  };

  const handlephonechange = (resolve, reject) => {
    if (phone_number !== "") {
      let a = {
        value: phone_number,
        pid: "none",
      };
      let data1 = Encrypt_Value(a, "vozo");
      axiosInstance
        .post("/vozonew/patphonecheck?type=patient", data1)
        .then((res) => {
          let data = Decrypt_Value(res.data, "vozo");
          if (data === "number not found") {
            setSameNumber(false);
            resolve("continue");
          } else if (
            data.id &&
            (data.id !== undefined || data.id !== "" || data.id !== null)
          ) {
            setSameNumber(true);
            setPhoneErrMsg("This phone number Already exist");
            resolve("break");
          } else {
            setSameNumber(false);
            resolve("continue");
          }
        })
        .catch((err) => {
          console.error(err);
          resolve("continue");
        });
    } else {
      resolve("continue");
    }
  };

  const createSaveAddMore = async () => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }
    const article = {
      fname: first_name,
      lname: last_name,
      email: email_id,
      phone: phone_number,
      external: externalID,
      prov_id: providerID,
    };

    if (first_name === "" || first_name === null) {
      setFnError(true);
      return;
    } else if (last_name === "" || last_name === null) {
      setLnError(true);
      return;
    } else if (email_id === "") {
      setMailError(true);
      setMailErrMsg("Enter Email address");
      return;
    } else if (phone_number === "") {
      setPhoneError(true);
      return;
    } else if (providerID === "" || providerID === null) {
      setProvErr(true);
      return;
    } else {
      let Data_enc = Encrypt_Value(article, "vozo");
      setDisable(true);
      axiosInstance
        .post("/vozo/patient", Data_enc)
        .then((response) => {
          if (typeof response.data === "number") {
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            setDisable(false);
            clientShow();
            history.push("/patient/demographics/" + response.data);
          } else if (response.data === "External ID is already present") {
            setIdError(true);
            setIdErrMsg("External ID is already present");
            setDisable(false);
          } else {
            OnChangeClientFail();
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            setDisable(false);
          }
        })
        .catch((err) => {
          setIdError(true);
          setIdErrMsg("External ID is already present");
          setDisable(false);
        });
    }
  };

  const OnChangeClientAdd = () => {
    ClientAdd(true);
    setTimeout(() => {
      ClientAdd(false);
    }, 1000);
  };

  const OnChangeClientFail = () => {
    ClientFailed(true);
    setTimeout(() => {
      ClientFailed(false);
    }, 1000);
  };

  const phoneChange = (e) => {
    if (!isNaN(e.target.value)) {
      const value = e.target.value.replace(/\s/g, "");
      setphonenumber(value);
      setPhoneError(false);
      setSameNumber(false);
    }
  };

  const externalId = (e) => {
    if (!isNaN(e.target.value)) {
      setExternalID(e.target.value);
      setIdError(false);
      setIdErrMsg("");
    }
  };
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const EmailCheck = (e) => {
    setemailid(e.target.value);

    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]{1,4}$/;
    if (regex.test(e.target.value)) {
      setMailError(false);
    } else {
      setMailError(true);
      setMailErrMsg("Enter valid e-mail address");
    }
  };

  const fnameSetVal = (e) => {
    setfirstname(e.target.value);
    setFnError(false);
  };

  const lnameSetVal = (e) => {
    setlastname(e.target.value);
    setLnError(false);
  };

  const provIdSetVal = (e) => {
    setproviderID(e);
    setProvErr(false);
  };

  // ------------------------------- Functions End----------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    let Data_frsh =
      "limit=40&authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&owner_id=" +
      owner_id +
      "&userID=" +
      userID +
      "&pat_share=" +
      storedNames.pat_g;

    let Data_enc = Encrypt_Value(Data_frsh, "vozo");
    axiosInstance
      .get("/vozonew/provider?search=" + Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");
        getproviderlist(DecodeVal);
      })
      .catch((err) => {
        getproviderlist([]);
      });
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (email_id !== "") {
          let data1 = Encrypt_Value(email_id, "vozo");
          axiosInstance
            .get("/vozonew/patmailcheck?search=" + data1)
            .then((res) => {
              let id = res.data.id;
              if (res.data === "Email not found") {
                setMailError1(false);
              } else if (id !== undefined || id !== "" || id !== null) {
                setMailError1(true);
                setMailErrMsg("This E-mail Already Exists");
              } else {
                setMailError1(false);
              }
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [email_id]);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <div id="client-create">
      <Form>
        <Modal.Header closeButton>
          <Modal.Title className="table-top">Create Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="first_name"
                style={
                  fnError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                value={first_name}
                onChange={(e) => fnameSetVal(e)}
                autoComplete="off"
              />
              {fnError ? (
                <p className="errormessage">Invalid first name</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="last_name"
                value={last_name}
                style={
                  lnError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={(e) => lnameSetVal(e)}
                autoComplete="off"
              />
              {lnError ? <p className="errormessage">Invalid last name</p> : ""}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="client-form-control">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="email_id"
                value={email_id}
                style={
                  mailError || mailError1
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={EmailCheck}
                autoComplete="off"
              />

              {mailError ? (
                <p className="errormessage">{mailErrMsg}</p>
              ) : mailError1 ? (
                <p className="errormessage">{mailErrMsg}</p>
              ) : (
                ""
              )}
              <span className="disable-text">
                To grant Client Portal access, enter an email address{" "}
              </span>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                id="pat_crt-inp_pho"
                name="phone"
                value={phone_number}
                style={
                  phoneError || sameNumber
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onKeyDown={handleKeyDown}
                onChange={phoneChange}
                autoComplete="off"
              />
              {phoneError ? (
                <p className="errormessage">Invalid Phone number</p>
              ) : (
                ""
              )}
              {sameNumber ? <p className="errormessage">{phoneErrMsg}</p> : ""}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>External ID</Form.Label>
              <Form.Control
                id="pat_crt-inp_exId"
                name="external_id"
                value={externalID}
                style={
                  idError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={externalId}
                autoComplete="off"
              />
              {idError ? <p className="errormessage">{idErrMsg}</p> : ""}
            </Form.Group>
            <Col>
              <Form.Group>
                <Form.Label>Provider</Form.Label>

                <Select
                  placeholder="Select Provider"
                  className="w-100 creat_provider ant-sel-hei34 FutVideoShow staterelative"
                  id="new-app-office"
                  value={providerID ? providerID : undefined}
                  // disabled={!storedNames.pat_g}
                  onChange={(e) => provIdSetVal(e)}
                  suffixIcon={<CustomSuffixIcon />}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.title ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {providerlist.map((s, ind) => (
                    <Option
                      key={`29${ind}`}
                      value={s.id}
                      className={`crt_cln-pro_li${ind}`}
                      title={`${s.fname} ${s.lname}`}
                    >
                      {`${s.fname} ${s.lname}`}
                    </Option>
                  ))}
                </Select>
                {provErr ? (
                  <p className="errormessage">Please Select Provider</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer className="client-button">
          <Button
            className="close_active close-btn"
            variant="light"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="close_active fill-btn"
            variant="outline-secondary"
            onClick={(e) => checkPhone(e, "more")}
          >
            Save and fill more details
          </Button>
          <Button
            className="save_active save-btn"
            variant="primary"
            disabled={disable}
            onClick={(e) => checkPhone(e, "save")}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(CreateClient);
