import React from "react";
import { Div, Text } from "../index";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { globalFont } from "../../components/utils/RepeatFunctions/Schedule";
import { SpanCont } from "../../components/setting/PatientPortalSettings/StyledComponents";
import { BsExclamationCircle } from "react-icons/bs";

const AvailabilityError = (props) => {
  let history = useHistory();

  const navigateAvailability = ({ type }) => {
    history.push("/schedule/apptemplate");
  };

  const title =
    props.type === "availability"
      ? "Can't Schedule Appointment"
      : "Can't Change Status";

  const paragraph =
    props.type === "availability"
      ? `First, you must set the provider's availability, which is their 'In
            Office' time for the day. Only then can you schedule an appointment`
      : `Status can be changed to '@ Arrived/Lobby', only at appointment date.`;

  return (
    <>
      <Modal.Body
        className="p-20 cur-point"
        show="flex"
        justifyContent="center"
        onClick={props.handleModalClick}
      >
        <Div
          width="fit-content"
          height="fit-content"
          display="block"
          backgroundColor={"#fffff"}
          borderColor={"#fffff"}
          borderRadius="8px"
        >
          <Div display="flex" alignItems="center" justifyContent="flex-start">
            <BsExclamationCircle
              style={{ fontSize: "16px", color: "#F24822" }}
            />

            <Text
              fontFamily={globalFont}
              fontWeight="600"
              fontSize="16px"
              lineHeight="normal"
              color={"#2E2E2E"}
              padding="0px 6px 2px 5px"
              align="left"
            >
              {title}
            </Text>
          </Div>

          <Text
            fontFamily={globalFont}
            fontWeight="400"
            fontSize="14px"
            lineHeight="normal"
            color="#2E2E2EBF"
            padding="8px 6px 8px 0px"
            align="left"
            marginBottom="0px"
          >
            {paragraph}
          </Text>

          {props.type === "availability" && (
            <Text
              fontFamily={globalFont}
              fontWeight="400"
              fontSize="14px"
              lineHeight="normal"
              color="#2E2E2EBF"
              padding="8px 6px 6px 0px"
              align="left"
              marginBottom="0px"
            >
              To set Provider availability,&nbsp;
              <SpanCont
                color="#2C7BE5"
                cursor="pointer"
                onClick={navigateAvailability}
              >
                Click here
              </SpanCont>
            </Text>
          )}
        </Div>
      </Modal.Body>
    </>
  );
};

export default AvailabilityError;
