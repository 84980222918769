import React, { useState, useEffect, useRef } from "react";
import { Row } from "../Message/styles";
import {
  Image,
  Text,
  Avatar,
  AvatarLetter,
  Div,
} from "../../../top_navbar/Notifications/styles";
import DocType from "../../../../assets/images/docType.png";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import moment from "moment";
import { ChatBox, ChatBoxMsg, Data, ChatBody } from "./Style";
import { FileInput } from "../../chatpopupBody/styles";
import { Modal, ModalDiv } from "../../../../StyledComponents";
import { BsInfoCircle } from "react-icons/bs";
import { memo } from "react";
import { globalFont } from "../../../utils/RepeatFunctions/Schedule";

const Chat = (props) => {
  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  var File_URL = finalurl + "/sites/" + DBHost + "/MessageImages/";

  var Test_URL = finalurl + "/sites/" + DBHost + "/documentimg/";

  var audioURL = finalurl + "/sites/" + DBHost + "/Voice_recordings/";

  const messageEl = useRef(null);
  const inputEl = useRef(null);
  const [data, setDat] = useState([]);
  const [drop, setDrop] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [prevAudio, setPrevAudio] = useState(null);
  const audioURLs = [];
  const dtFormat = localStorage.getItem("dateFormat");
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrop(true);
    } else if (e.type === "dragleave") {
      setDrop(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const ext = file.type.split("/")[1];

      switch (ext) {
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "pdf":
          props.setSelectedFile(file);
          setDrop(false);
          break;
        default:
          props.setSelectedFile();
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 1500);
          setDrop(false);
      }
    }
  };

  const handlePlay = (audioElement) => {
    if (
      currentAudio &&
      currentAudio !== audioElement
      // ||(currentAudio && currentAudio !== prevAudio)
    ) {
      // prevAudio && prevAudio.pause();
      currentAudio && currentAudio.pause();
    }
    setCurrentAudio(audioElement);
    // setPrevAudio(currentAudio);
    audioElement.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  };

  const handlePause = () => {
    if (prevAudio) {
      prevAudio.pause();
      setPrevAudio(null);
    }
  };

  useEffect(() => {
    const filteredArray = props.data.filter((item) => item.message !== null);
    setDat(filteredArray);
  }, [props]);

  useEffect(() => {
    setTimeout(() => {
      messageEl.current.scrollIntoView(
        {
          behavior: "smooth",
          block: "end",
          inline: "center",
        },
        2000
      );
    });
  }, [data]);
  return (
    <>
      <ChatBody overflow="none">
        <Data
          height="398px"
          width="100%"
          padding="16px"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          drop={drop}
        >
          <FileInput
            ref={inputEl}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          {!props.loading ? (
            data.length !== 0 ? (
              data.map((item, ind) => {
                return (
                  <ChatBox
                    user={item.sender === "Provider" ? true : false}
                    type={item.type}
                    key={ind}
                    marginLeft={
                      (item.type === "image" ||
                        item.type === "doc" ||
                        item.type === "audio") &&
                      item.sender === "Provider"
                        ? "auto"
                        : ""
                    }
                  >
                    {item.type !== "date" &&
                      item.sender !== "Provider" &&
                      item.message !== null && (
                        <>
                          {props.patpic === null ? (
                            <Avatar width="32px" height="32px">
                              <AvatarLetter top="6px" left="10px">
                                {item.username.charAt(0).toUpperCase()}
                              </AvatarLetter>
                            </Avatar>
                          ) : (
                            <Image
                              src={Test_URL + props.patpic}
                              alt="image-failed"
                              loading="lazy"
                              width="32px"
                              borderRadius="40px"
                              height="32px"
                              mr="8px"
                            />
                          )}
                        </>
                      )}
                    <ChatBoxMsg
                      user={item.sender === "Provider" ? true : false}
                      type={item.type}
                      day={
                        item.type === "date" &&
                        moment(item.date).calendar(null, {
                          sameDay: "[Today]",
                          nextDay: "[Tomorrow]",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday]",
                          lastWeek: dtFormat,
                          sameElse: dtFormat,
                        })
                      }
                    >
                      {item.type === "date" &&
                        moment(item.date).calendar(null, {
                          sameDay: "[Today]",
                          nextDay: "[Tomorrow]",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday]",
                          lastWeek: dtFormat,
                          sameElse: dtFormat,
                        })}

                      {item.imageUrl !== null && item.type === "audio" && (
                        <Div display="flex" flexDirection="column">
                          <div key={ind}>
                            <audio
                              className="aud-top-nav1"
                              controls
                              // style={{ height: "40px", width: "250px" }}
                              ref={(audioElement) =>
                                (audioURLs[ind] = audioElement)
                              }
                              onPlay={() => handlePlay(audioURLs[ind])}
                              onPause={handlePause}
                            >
                              <source
                                src={audioURL + item.imageUrl}
                                type="audio/wav"
                              />
                            </audio>
                          </div>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </Div>
                      )}

                      {item.type === "text" && item.message !== null && (
                        <>
                          <Text
                            width="unset"
                            fontFamily={globalFont}
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="17px"
                            textAlign="left"
                            MaxWidth="391px"
                            wordBreak="break-word"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                          >
                            {item.message}
                          </Text>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}
                      {item.type === "image" && (
                        <>
                          <Row dis="flex" flexDirection="column">
                            <Image
                              cursor="pointer"
                              MaxWidth="221px"
                              MaxHeight="137px"
                              objectFit="contain"
                              display="block"
                              src={item.imageUrl && File_URL + item.imageUrl}
                              alt=" "
                              onClick={() => {
                                props.handlePreview(File_URL + item.imageUrl);
                                props.setViewType("image");
                              }}
                            />
                            <Text
                              fontFamily={globalFont}
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="17px"
                              textAlign="left"
                              color="unset"
                              display="inline"
                              width="100%"
                            >
                              {item.message}
                            </Text>
                          </Row>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}
                      {item.type === "doc" && (
                        <>
                          <Row dis="flex" flexDirection="column">
                            <Image
                              cursor="pointer"
                              MaxWidth="221px"
                              MaxHeight="137px"
                              objectFit="contain"
                              display="block"
                              src={DocType}
                              alt=" "
                              onClick={() => {
                                props.handlePreview(File_URL + item.imageUrl);
                                props.setViewType("doc");
                              }}
                            />
                            <Text
                              fontFamily={globalFont}
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="17px"
                              color="unset"
                              display="inline"
                              width="100%"
                              textAlign="left"
                            >
                              {item.message}
                            </Text>
                          </Row>
                          <Text
                            width="unset"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                            margin="auto 0 0 10px"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}
                    </ChatBoxMsg>
                    {item.type !== "date" && item.sender === "Provider" && (
                      <>
                        {props.profileimage === null ||
                        props.profileimage === "" ? (
                          <Avatar width="32px" height="32px" minWidth="32px">
                            <AvatarLetter top="6px" left="7px">
                              {props.providerName.charAt(0).toUpperCase()}
                            </AvatarLetter>
                          </Avatar>
                        ) : (
                          <Image
                            src={props.profileimage}
                            alt="image-failed"
                            loading="lazy"
                            borderRadius="40px"
                            height="32px"
                            width="32px"
                            mr="8px"
                          />
                        )}
                      </>
                    )}
                  </ChatBox>
                );
              })
            ) : (
              <Div
                display="flex"
                justifyContent="center"
                fontWeight="500"
                fontSize="14px"
                lineHeight="17px"
                color="#8A969F"
                backColor="unset"
              >
                No Messages....
              </Div>
            )
          ) : (
            <Div
              display="flex"
              justifyContent="center"
              fontWeight="500"
              fontSize="14px"
              lineHeight="17px"
              color="#8A969F"
              backColor="unset"
            >
              loading Messages....
            </Div>
          )}
          <Row ref={messageEl}></Row>
        </Data>
      </ChatBody>
      <Modal show={showAlert} className="alert-popup-message">
        <ModalDiv className="alert msg alert-danger" role="alert">
          <BsInfoCircle className="icons-bs" />
          File Not allowed
        </ModalDiv>
      </Modal>
    </>
  );
};

export default memo(Chat);
