import styled from "styled-components";
import { BodyDiv, DataDiv } from "../../../top_navbar/Notifications/styles";
import { globalColor } from "../../../utils/RepeatFunctions/Schedule";

export const ChatBox = styled.div`
  align-self: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  justify-content: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  margin-bottom: 18px;
  max-width: ${({ type }) =>
    type === "date" ? "100%" : type === "text" ? "100%" : "fit-content"};
  text-align: ${({ type }) => (type === "date" ? "center" : "left")};
  display: flex;
  flex-direction: row;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;

export const ChatBoxMsg = styled.div`
  background: ${({ user, day }) =>
    day ? "#ffff" : user ? globalColor : " #C4C4C4"};
  max-width: ${({ user }) => user && "93%"};
  color: ${({ user, day }) =>
    day
      ? day === "Today"
        ? "#2E2E2E"
        : "#BAC0C6"
      : user
      ? " #FFFFFF"
      : "#000000"};
  border-radius: ${({ type }) => (type === "date" ? "4px" : "8px")};
  padding: ${({ type }) => (type === "date" ? "7px 20px" : "4px 8px")};
  margin-right: ${({ user }) => user && "12px"};
  display: flex;
  border: ${({ day }) => day && "1px solid #CBD5E0"};
  position: relative;
  min-height: 20px;

  ${({ user }) =>
    user &&
    `
&::after {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-left-color: ${globalColor};
  border-right: 0;
  right: -6px;
  top: 26px;
  margin-top: -19px;
}
`};
  ${({ user, type }) =>
    !user &&
    type !== "date" &&
    `
&::before {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-right-color: #C4C4C4;
  border-left: 0;
  left: 11px;
  top: 1px;
  margin-left: -16px;
}
`};
`;
export const ChatBody = styled.div`
  overflow: ${(p) => p.overflow && p.overflow};
`;
export const Data = styled(DataDiv)`
  width: ${(p) => p.width && p.width};
  max-height: 418px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
