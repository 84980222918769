import { call, delay, put } from "redux-saga/effects";
import {
  setProviderData,
  changeAlert,
  setErrorData,
  changeInvalidValue,
} from "../../StateManagement/Reducers/ScheduleWidgetState";
import { providerListApi, BookScheduleApi } from "../Apis/WidgetApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

export function* providerListWorker({ payload }) {
  const data = Encrypt_Value(payload, "schedule_widget");
  try {
    const res = yield call(providerListApi, data);

    if (res.status === 200) {
      const result = Decrypt_Value(res.data, "schedule_widget");
      if (result) {
        yield put(setProviderData(result));
      } else {
        yield put(setProviderData([]));
      }
    }
  } catch (e) {
    yield put(setProviderData([]));
    console.error(e.message);
  }
}

export function* saveScheduleWorker({ payload }) {
  const data = Encrypt_Value(payload, "schedule_widget");
  try {
    const res = yield call(BookScheduleApi, data);
    const decrypted = Decrypt_Value(res.data, "schedule_widget");
    console.log(decrypted, "decrypted");
    if (res.status === 200) {
      if (
        decrypted?.error === "email exist" ||
        decrypted?.error === "phone exist"
      ) {
        yield put(
          changeAlert({
            show: true,
            type: "failed",
          })
        );
        yield put(setErrorData(decrypted));
        yield delay(1500);
        yield put(changeAlert({ show: false, type: "failed" }));
      } else if (decrypted === "success") {
        yield put(
          changeAlert({
            show: true,
            type: "success",
          })
        );
        yield delay(10000);
        yield put(changeAlert({ show: false, type: "success" }));
        let src = "";
        if (window.location.hostname === "localhost") {
          src = "http://localhost/";
        } else {
          src = "https://vozohealth.com/";
        }
        window.parent.postMessage("closeIframe", src);
      } else {
        yield put(
          changeAlert({
            show: true,
            type: "failed",
          })
        );
        yield delay(2000);
        yield put(changeAlert({ show: false, type: "failed" }));
        let src = "";
        if (window.location.hostname === "localhost") {
          src = "http://localhost/";
        } else {
          src = "https://vozohealth.com/";
        }
        window.parent.postMessage("closeIframe", src);
      }
    }
  } catch (e) {
    yield put(
      changeAlert({
        show: true,
        type: "failed",
      })
    );
    yield delay(2000);
    yield put(changeAlert({ show: false, type: "failed" }));
    let src = "";
    if (window.location.hostname === "localhost") {
      src = "http://localhost/";
    } else {
      src = "https://vozohealth.com/";
    }
    window.parent.postMessage("closeIframe", src);
  }
}
