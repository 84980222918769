import styled from "styled-components";
import { Modclosebtn } from "../../billing/CardTransactions/transactionDetails/style";
import { Image } from "../../setting/PatientPortalSettings/StyledComponents";
import { Modal } from "../../billing/CardTransactions/transactionDetails/style";
import { Input } from "../../billing/CardTransactions/styles";
import { AvatarLetter } from "../../top_navbar/Notifications/styles";
import { Data } from "../../top_navbar/Notifications/chats/Style";
export const Mod = styled(Modal)`
  display: ${({ show }) => (show ? "flex" : "none")};
`;

export const Modbtn = styled(Modclosebtn)`
  margin: ${(p) => p.margin && p.margin};
`;

export const Images = styled(Image)`
  border-top-right-radius: ${(p) => p.btopright && p.btopright};
  border-top-left-radius: ${(p) => p.btopleft && p.btopleft};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  margin: ${(p) => p.margin && p.margin};
`;

export const Inputstyled = styled(Input)`
  display: ${(p) => p.display && p.display};
`;

export const AvatarLetterstyled = styled(AvatarLetter)`
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
`;

export const DataScroll = styled(Data)`
  max-height: ${(p) => p.maxHeight && p.maxHeight};
`;
