import { createSlice } from "@reduxjs/toolkit";

export const ScheduleWidgetSlice = createSlice({
  name: "ScheduleWidget",
  initialState: {
    popupSpinner: false,
    inputValue: {
      fname: "",
      lname: "",
      pType: "",
      aType: "",
      speciality: "",
      provider: "",
      prefDate: "",
      prefTime: "",
      phoneNumber: "",
      email: "",
      reason: "",
      emailErrorMsg: "",
      hideTopNavbar: false,
      errorData: [],
    },
    invalidValue: {
      fname: false,
      lname: false,
      pType: false,
      aType: false,
      speciality: false,
      provider: false,
      prefDate: false,
      prefTime: false,
      phoneNumber: false,
      email: false,
      reason: false,
      emailExist: false,
      nameError: false,
      phoneError: false,
      phoneExist: false,
    },
    providerData: [],
    showAlert: {
      show: false,
      type: "",
    },
    modifyAppointmentData: [],
    modifyAppointment: false,
  },
  reducers: {
    changeValue: (state, action) => {
      const { name, value } = action.payload;

      if (value !== undefined) {
        state.inputValue[name] = value;
      }
    },
    changeInvalidValue: (state, action) => {
      const { name, value } = action.payload;

      state.invalidValue[name] = value;
    },
    setErrorData: (state, action) => {
      const { payload } = action;

      state.inputValue.errorData = action.payload;

      if (payload?.error === "email exist") {
        state.invalidValue.emailExist = true;
        if (payload.name_check === 0) {
          state.invalidValue.nameError = true;
        } else {
          state.invalidValue.nameError = false;
        }
        if (payload.phone_check === 0) {
          state.invalidValue.phoneError = true;
        } else {
          state.invalidValue.phoneError = false;
          state.invalidValue.phoneExist = false;
        }
      } else if (payload?.error === "phone exist") {
        state.invalidValue.phoneExist = true;
      }
    },
    getProviderData: (state, action) => {
      return state;
    },
    setProviderData: (state, action) => {
      state.providerData = action.payload;
    },
    changeAlert: (state, action) => {
      state.showAlert = action.payload;

      if (action.payload.type === "success") {
        state.inputValue = {
          fname: "",
          lname: "",
          pType: "",
          aType: "",
          speciality: "",
          provider: "",
          prefDate: "",
          prefTime: "",
          phoneNumber: "",
          email: "",
          reason: "",
          emailErrorMsg: "",
          hideTopNavbar: false,
        };
      }
      state.popupSpinner = false;
    },
    startBookSchedule: (state) => {
      state.popupSpinner = true;
    },
    endBookSchedule: (state) => {
      state.popupSpinner = false;
    },
    openToModify: (state, action) => {
      const { data, value } = action.payload;
      state.modifyAppointment = value;
      state.modifyAppointmentData = data["row"];
    },
  },
});

export const {
  changeValue,
  changeInvalidValue,
  setProviderData,
  getProviderData,
  changeAlert,
  startBookSchedule,
  endBookSchedule,
  openToModify,
  setErrorData,
} = ScheduleWidgetSlice.actions;

export default ScheduleWidgetSlice.reducer;
