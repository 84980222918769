import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import { SendOtpApi } from "../Apis/PreloginApi";
import { setEmailRes } from "../../StateManagement/Reducers/PreLoginState";

export function* SendOtpWorker({ payload }) {
  let encrypted = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(SendOtpApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      if (Data.status !== "Failure") {
        yield put(setEmailRes(Data));
        let encryptOtp = Encrypt_Value(Data.output, "vozo");
        localStorage.setItem("twostepotp", encryptOtp);
      } else {
        yield put(setEmailRes({ status: "", output: "" }));
      }
    }
  } catch (e) {
    yield put(setEmailRes({ status: "", output: "" }));
    console.log(e.message);
  }
}
