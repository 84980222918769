import React from "react";
import styled from "styled-components";

import { AiOutlineSearch } from "react-icons/ai";

import { Div, Text } from "../../StyledComponents/index";
import {
  SearchInputText,
  ThumbImage,
  ThumbDoc,
  Image,
  SearchInputText1,
} from "./Styles";

import MicroPhone_Start from "../../assets/images/Microphone_Start.png";
import { hoverColor } from "../utils/RepeatFunctions/Schedule";

const SearchProvider = ({ searchVal }) => {
  const Onsearch = (e) => {
    searchVal(e.target.value);
  };
  return (
    <Div
      width="88%"
      display="flex"
      padding="0px 0px 0px 10px"
      height="38px"
      borderRadius="8px"
      border="1px solid #e4e4e4"
    >
      <Div padding="3px 0px 0px 0px">
        <AiOutlineSearch
          fontSize="22px"
          fill="#666666"
          fontWeight="100"
          viewBox="0 -200 1024 1024"
        />
      </Div>
      <SearchInputText onChange={Onsearch} placeholder="Search" />
    </Div>
  );
};
const SearchPatient = ({ searchVal }) => {
  const Onsearch = (e) => {
    searchVal(e.target.value);
  };
  return (
    <Div
      width="88%"
      display="flex"
      padding="0px 0px 0px 10px"
      height="38px"
      borderRadius="8px"
      border="1px solid #e4e4e4"
    >
      <Div padding="3px 0px 0px 0px">
        <AiOutlineSearch
          fontSize="18px"
          fill="#666666"
          fontWeight="100"
          viewBox="0 -200 1024 1024"
        />
      </Div>
      <SearchInputText1
        width="88%"
        fontSize="12px"
        onChange={Onsearch}
        placeholder="Search Patient by Name,SSN,Email,DOB"
      />
    </Div>
  );
};
const TnumbComponent = ({ message, variant }) => {
  return (
    <Div display="flex" background="none">
      {variant === "image" && (
        <>
          <ThumbImage />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 0px"
          >
            {message}
          </Text>
        </>
      )}
      {variant === "doc" && (
        <>
          <ThumbDoc />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 0px"
          >
            {message}
          </Text>
        </>
      )}

      {variant === "audio" && (
        <>
          <Image
            src={MicroPhone_Start}
            height="20px"
            width="16px"
            margin="0px 6px 0px 0px"
          />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 0px"
          >
            {message}
          </Text>
        </>
      )}
    </Div>
  );
};
export { SearchProvider, TnumbComponent, SearchPatient };

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  border: ${(p) => p.border && p.border};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const AvatarLetter = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const AvatarImage = styled(Image)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  min-width: ${(p) => p.minWidth && p.minWidth};
  border: ${(p) => p.border && p.border};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "-2px")};
`;
export const Images = styled.img`
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  cursor: ${({ cursor }) => cursor && cursor};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};
`;
export const Button = styled.button`
  cursor: ${(p) => p.cursor && p.cursor};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  border-color: ${(p) => p.borderColor && p.borderColor};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin: ${(p) => p.margin && p.margin};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    position: ${(p) => p.svgPosition && p.svgPosition};
    top: ${(p) => p.svgTop && p.svgTop};
    left: ${(p) => p.svgLeft && p.svgLeft};
  }
  ${(p) =>
    p.disabled &&
    `
      cursor: not-allowed;
      `}
  &:hover {
    &.bgFFF {
      background-color: #f4f6f9;
    }
    &.bg-2c7be5 {
      background-color: ${hoverColor};
    }
  }
  &.detailupdatebtn {
    @media (max-width: 700px) {
      padding: 4px 4px 4px 4px;
      margin-left: 15px;
    }
  }
`;
