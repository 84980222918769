import axios from "axios";

let DBHost;
var headers = {};
let finalloginurl;
if (window.location.href.includes("localhost")) {
  DBHost = "default";
  finalloginurl = process.env.REACT_APP_BACKEND_URL;
} else {
  let firstDot = window.location.hostname.split(".");
  if (
    firstDot[0] !== "ehr" &&
    firstDot[0] !== "ehr1" &&
    firstDot[0] !== "test" &&
    firstDot[0] !== "mumbai" &&
    firstDot[0] !== "dev" &&
    firstDot[0] !== "production"
  ) {
    DBHost = firstDot[0];
    finalloginurl = process.env.REACT_APP_BACKEND_URL.replace("oemr", DBHost);
  } else {
    DBHost = "default";
    finalloginurl = process.env.REACT_APP_BACKEND_URL.replace(
      "oemr",
      firstDot[0]
    );
  }
}

headers.Authorization = `${localStorage.token}`;

var baseURL = finalloginurl + DBHost + "/api";

export const providerListApi = async (data) =>
  await axios.post(baseURL + "/schedule_widget/get/provider/list", data);

export const BookScheduleApi = async (data) =>
  await axios.post(baseURL + "/schedule_widget/book/schedule", data);
