import styled from "styled-components";
import { Inputstyled } from "../patient/AssignToPatient/style";

export const DatePickerInput = styled(Inputstyled)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
  border-right: ${(p) => p.borderRight && p.borderRight};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #7e7c7c !important;
    padding: 0px 0px !important;
    font-size: 14px !important;
  }
`;
export const DayButtons = styled.button`
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  &:disabled {
    background-color: #cccccc !important;
    color: #666666 !important;
    margin-top: 4px !important;
    border-radius: 0px !important;
  }
`;
