import { React } from "react";
import JoinUs from "../../../assets/images/JoinUs.png";
import logo from "../../../assets/images/Group 2076.png";
import { Div, Image, Button, Link } from "../../../StyledComponents";
import { globalColor, globalFont } from "../../utils/RepeatFunctions/Schedule";

const AccessDenied = () => {
  return (
    <Div textAlign="center" height="100vh" padding="35px">
      <Image
        loading="lazy"
        src={logo}
        height="33px"
        width="140px"
        objectFit="contain"
        display="block"
      />
      <Image
        src={JoinUs}
        display="block"
        maxWidth="450px"
        maxHeight="450px"
        width="100%"
        height="100%"
        marginLeft="auto"
        marginRight="auto"
        alt="access denied"
      />
      <Button
        background={globalColor}
        display="block"
        height="56px"
        fontFamily={globalFont}
        fontSize="16px"
        fontWeight="500"
        lineHeight="26px"
        padding="12px 10%"
        borderRadius="8px"
        marginLeft="auto"
        marginRight="auto"
        marginTop="40px"
        cursor="unset"
      >
        You don’t have access to this page, please register to get access. To
        register &nbsp;
        <Link
          href="https://vozohealth.com"
          fontWeight="700"
          color="#FFFFFF"
          textDecoration="underline"
        >
          click here
        </Link>
      </Button>
    </Div>
  );
};

export default AccessDenied;
