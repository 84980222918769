import React, { useState } from "react";
import { Button, Div, Form, FormLabel, Text } from "../../../StyledComponents";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

import Loader from "../../../components/popups/Loading";
import MessageModal from "../../../components/login/ForgotPassword/MessageModal";
import {
  GetEmailRes,
  ResendSucsFalse,
  setResendDis,
} from "../../../StateManagement/Reducers/PreLoginState";
import { SpanCont } from "../../../components/setting/PatientPortalSettings/StyledComponents";
import {
  Input,
  ResendButton,
  FormGroupInline,
} from "../../SubscriptionRenewal/OtpVerification/style";

const EmailVerification = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const dispatch = useDispatch();
  const [Otp, setOtp] = useState(new Array(6).fill(""));
  const [otpErr, setOtpErr] = useState(false);
  const [btndis, setbtndis] = useState(false);

  const email = localStorage.getItem("verified_email")
    ? localStorage.getItem("verified_email")
    : "";

  const sendOtp = useSelector((state) => state.PreLogin.sendOtp);
  const showLoading = useSelector((state) => state.PreLogin.showLoading);
  const disBtn = useSelector((state) => state.PreLogin.btnDisable);
  const resend = useSelector((state) => state.PreLogin.resendSucs);

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //

  const HandleVerify = (e) => {
    e.preventDefault();
    setbtndis(true);
    dispatch(setResendDis(true));
    if (Otp.join("") == "") {
      setOtpErr(true);
      setbtndis(false);
      dispatch(setResendDis(false));
      return false;
    }
    const Accountotp = localStorage.getItem("twostepotp")
      ? Decrypt_Value(localStorage.getItem("twostepotp"), "vozo")
      : "";
    if (Accountotp == Otp.join("")) {
      setTimeout(() => {
        window.location.href = "/";
        localStorage.removeItem("twoStepVerify");
        localStorage.removeItem("twostepotp");
        localStorage.removeItem("verified_email");
      }, 1000);
    } else {
      setOtpErr(true);
      setTimeout(() => {
        setbtndis(false);
        dispatch(setResendDis(false));
      }, 2000);
    }
  };

  const HandleOtp = (e, i) => {
    setOtpErr(false);
    if (isNaN(e.value)) return;

    setOtp([...Otp.map((r, ind) => (ind === i ? e.value : r))]);

    if (e.parentElement.nextSibling) {
      e.parentElement.nextSibling.firstChild.focus();
    }
  };

  const KeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.parentElement.previousSibling) {
        e.target.parentElement.previousSibling.firstChild.focus();
      }
    }
  };

  const FieldPaste = (evt, i) => {
    const pasteVal = evt.clipboardData.getData("Text").toString();
    setOtpErr(false);
    if (isNaN(pasteVal)) return;
    setOtp([...Otp.map((r, ind) => pasteVal.at(ind))]);
  };

  const HandleResend = () => {
    setOtpErr(false);
    setbtndis(true);
    localStorage.setItem("twostepotp", "");
    setOtp(new Array(6).fill(""));
    dispatch(GetEmailRes({ email, mode: "resend" }));
  };
  // ------------------------------- Functions End--------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    if (sendOtp) {
      dispatch(GetEmailRes({ email, mode: "initial" }));
    }
  }, []);

  useEffect(() => {
    if (resend === true) {
      setTimeout(() => {
        dispatch(ResendSucsFalse());
        setbtndis(false);
      }, 2000);
    }
  }, [resend]);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <>
          <Div
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            background=" rgba(240, 244, 247, 1)"
          >
            <Div
              background="rgb(255, 255, 255)"
              boxShadow="rgba(196, 203, 214, 0.1) 0px 6px 58px"
              borderRadius=" 16px"
              textAlign="center"
              padding=" 40px 32px"
            >
              <Text
                fontSize="36px"
                fontWeight="700"
                fontFamily="opensans"
                lineHeight="44px"
                marginBottom="16px"
              >
                Verify your Account
              </Text>
              <Div
                textAlign="center"
                maxWidth="430px"
                marginLeft="auto"
                marginRight="auto"
                marginBottom="32px"
              >
                <Text
                  marginTop="16px"
                  fontSize="20px"
                  fontWeight="400"
                  lineHeight="26px"
                  color="#667085"
                  fontFamily="Urbanist"
                >
                  Check you email, we just sent you a verification code to
                  &nbsp;
                  <SpanCont color="#407BFF" fontWeight="700">
                    {email}
                  </SpanCont>
                </Text>
              </Div>

              <Form>
                <FormGroupInline display="flex" marginBottom="20px">
                  {Otp.map((row, i) => {
                    return (
                      <Div display="inline-grid" width="25%" key={i}>
                        <Input
                          key={i}
                          width="80px"
                          height="80px"
                          fontSize="36px"
                          lineHeight="44px"
                          fontWeight="700"
                          fontFamily="'Urbanist'"
                          maxLength="1"
                          textAlign="center"
                          maxlength="1"
                          value={row}
                          onChange={(e) => HandleOtp(e.target, i)}
                          onFocus={(e) => e.target.select()}
                          onKeyUp={(e) => KeyDown(e, i)}
                          onPaste={(e) => FieldPaste(e, i)}
                        />
                      </Div>
                    );
                  })}
                </FormGroupInline>
                {otpErr && (
                  <Div width="100%" gridColumn="1/5">
                    <FormLabel
                      textAlign="center"
                      fontSize="12px"
                      color="rgba(226, 61, 40, 1)"
                      fontWeight="400"
                      fontFamily="opensans,sans-serif"
                    >
                      OTP is invalid
                    </FormLabel>
                  </Div>
                )}
                <Button
                  padding="13px 190px"
                  height="48px"
                  background="#2C7BE5"
                  hoverBackColor="rgba(64, 123, 255, 1)"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="opensans,sans-serif"
                  marginTop="20px"
                  disabled={btndis}
                  onClick={(e) => HandleVerify(e)}
                >
                  Verify Account
                </Button>
              </Form>
              <Text
                textAlign="center"
                marginTop="16px"
                fontSize="18px"
                fontWeight="400"
                lineHeight="26px"
                color="#667085"
                fontFamily="Urbanist"
              >
                Don’t receive verification code?
                <ResendButton
                  disabled={disBtn}
                  cursor={disBtn ? "not-allowed" : "pointer"}
                  onClick={HandleResend}
                  fontSize="18px"
                >
                  &nbsp;Resend Code
                </ResendButton>
              </Text>
            </Div>
          </Div>
          <MessageModal
            active={resend}
            color="success"
            onHide={() => dispatch(ResendSucsFalse())}
          >
            Mail ReSent successfully to the mail address
          </MessageModal>
        </>
      )}
    </>
  );
};

export default EmailVerification;
