import styled from "styled-components";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";
import { Div } from "../../../billing/CardTransactions/styles";
import { Text } from "../styles";
import { BsFillImageFill, BsFillFileEarmarkFill } from "react-icons/bs";
import { globalColor } from "../../../utils/RepeatFunctions/Schedule";

export const Search = styled.div`
  height: 40px;
  background: #f4f6fc;
  padding: 6px 0px 13px 21px;
`;

export const SearchInputText = styled.input.attrs({
  placeholderTextColor: "red",
})`
  background: none;
  border: none;
  box-shadow: none;
  width: ${(p) => p.width && p.width};
  font-size: 16px;
  padding-left: 5px;
  outline-offset: -26px;
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  &:focus {
    outline: none;
  }
`;

export const Row = styled(Div)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  cursor: ${(p) => p.cursor && p.cursor};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  top: ${(p) => p.top && p.top};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
`;
export const Div1 = styled.div``;
export const Attach = styled(AiOutlinePaperClip)`
  margin-right: 5px;
  width: 25px;
  height: 25px;
  color: #6d5dd3;
  cursor: pointer;
`;

export const Close = styled(BsXLg)`
  margin-left: auto;
  cursor: pointer;
  color: ${globalColor};
`;
export const DowloadBtn = styled(BsDownload)`
  cursor: pointer;
  color: ${globalColor};
  font-weight: bold;
  margin-left: 20px;
`;

export const TnumbComponent = ({ message, variant }) => {
  return (
    <Row dis="flex">
      {variant === "image" && (
        <>
          <ThumbImage />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
      {variant === "doc" && (
        <>
          <ThumbDoc />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
    </Row>
  );
};

export const ThumbImage = styled(BsFillImageFill)`
  margin-top: 2.5px;
`;
export const ThumbDoc = styled(BsFillFileEarmarkFill)`
  margin-top: 2.5px;
`;
