import React, { useState, useEffect } from "react";
import { Span } from "../../Modules/Schedule/PracticeDashboard/styles";
import {
  ModalFooter1,
  ModalsendFax,
  TextArea,
} from "../../Modules/Patient/FaxQueue/styles";
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalDialog,
} from "../setting/PatientPortalSettings/StyledComponents";
import { Div, Button, PageTitle, Label, WenoDiv } from "../../StyledComponents";
import { Input, ErrorMessage } from "../setting/Inventory/styles";
import { Supportclosebtn } from "../../Modules/Patient/Revisions/styled";
import { FaTimes } from "react-icons/fa";
import { Select } from "antd";
import CustomSuffixIcon from "./CustomSuffixIcon";
import axiosInstance from "../../axios/axios";
import { BsXCircle } from "react-icons/bs";
import { BsFileEarmarkText } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import StarRating from "./Starrating";
import { useSelector } from "react-redux";

function WenoSupport() {
  //-----------------------------State and  Variables Start----------------------------------------------- //

  const [showSupport, setShowSupport] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const { Option } = Select;
  const [prior, setPrior] = useState("Low");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [dragdropErr, setdragdropErr] = useState(false);
  const [fileErrMsg, setFileErrMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [ModalMsg, setModalMsg] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [files, setFiles] = useState([]);
  const [subjectErr, setSubjectErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [btnDisable1, setbtnDisable1] = useState(false);
  const [reset_rating_point, setreset_rating_point] = useState(0);
  const [btnDisable2, setbtnDisable2] = useState(false);
  const [feedbackdescription, setFeedbackDescription] = useState("");
  const [ratingErr, setRatingErr] = useState(false);
  const [navData1, setNavData1] = useState("iconopen");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var showAdd1 = useSelector((state) => state.Revision?.showAdd1);
  var showAdd2 = useSelector((state) => state.Revision?.showAdd2);
  var Showcontact = useSelector((state) => state.Revision?.Showcontact);

  var NavData1 = useSelector((state) => state.Schedule?.NavData);
  //-----------------------------State and  Variables End----------------------------------------------- //

  //-----------------------------Function Start--------------------------------------------------------- //

  const modClose = () => setModalAlerShow(false);
  const modClose1 = () => setModalAlerShow1(false);

  const handleOpen = () => {
    setShowSupport(true);
  };
  const handleOpenFeedback = () => {
    setShowFeedback(true);
  };

  const handleClose = () => {
    setShowSupport(false);
    setdragdropErr(false);
    setFileNames([]);
    setDescription("");
    setSubject("");
    setPrior("Low");
    setFiles([]);
    setDescriptionErr(false);
    setSubjectErr(false);
    setbtnDisable1(false);
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
    setRatingErr(false);
    setbtnDisable2(false);
    setFeedbackDescription("");
    setreset_rating_point(0);
  };
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };
  const priorChange = (e) => setPrior(e);
  const subjectChange = (e) => {
    setSubject(e.target.value);
    setSubjectErr(false);
  };
  const descriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionErr(false);
  };
  const feedbackdescriptionChange = (e) => {
    setFeedbackDescription(e.target.value);
  };
  const handlefeedbackSave = () => {
    let err = 0;
    if (reset_rating_point === 0) {
      err = 2;
    }
    if (err > 0) {
      setRatingErr(true);
      return false;
    }
    const article = {
      feebackdescription: feedbackdescription,
      rating_point: reset_rating_point,
      user_id: userID,
    };
    let data1 = Encrypt_Value(article, "vozo");
    setbtnDisable2(true);

    axiosInstance
      .post("vozo/feedbackrating/insert", data1)
      .then((response) => {
        setModalMsg("Feedback Email Sent Successfully");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);
          setbtnDisable2(false);
        }, 1500);
        handleCloseFeedback();
      })
      .catch((error) => {
        setModalMsg("Failed to save Feedback");
        setModalAlerShow1(true);
        setTimeout(() => {
          setModalAlerShow1(false);
          setbtnDisable2(false);
        }, 1500);
        console.log(error.message);
      });
  };
  const ratingChanged = (newRating) => {
    setRatingErr(false);
    setreset_rating_point(newRating);
  };
  const changeimgpre = (e) => {
    const newFiles = Array.from(e.target.files);

    const validExtensions = ["txt", "png", "pdf", "jpg", "jpeg", "csv"];

    const currentFileNames = new Set(fileNames);
    const maxFiles = 3;

    if (newFiles.length === 0) {
      return;
    }
    const invalidFiles = newFiles.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return (
        !validExtensions.includes(extension) && !currentFileNames.has(file.name)
      );
    });

    if (invalidFiles.length > 0) {
      setdragdropErr(true);
      setFileErrMsg("Enter Valid File");
      return;
    }

    const filteredFiles = newFiles
      .filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        return (
          validExtensions.includes(extension) &&
          !currentFileNames.has(file.name)
        );
      })
      .slice(0, 3 - fileNames.length);
    if (fileNames.length + filteredFiles.length > maxFiles) {
      setdragdropErr(true);
      setFileErrMsg(`You can only upload up to ${maxFiles} files.`);
      return;
    }

    if (filteredFiles.length > 0) {
      const newFileNames = [
        ...fileNames,
        ...filteredFiles.map((file) => file.name),
      ];
      if (newFileNames.length === 3) {
        setbtnDisable1(true);
      }
      setFileNames(newFileNames);
      setdragdropErr(false);
      setFileErrMsg("");
      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };
  function fileremove(fileName) {
    const updatedFiles = fileNames.filter((name) => name !== fileName);
    setFileNames(updatedFiles);
    const updatedFilesnames = files.filter((file) => file.name !== fileName);
    setFiles(updatedFilesnames);
    if (updatedFilesnames.length < 3) {
      setbtnDisable1(false);
    }
  }

  const handleSave = () => {
    if (dragdropErr) {
      return false;
    }
    let err = 0;
    if (subject === "") {
      setSubjectErr(true);
      err = 2;
    }
    if (description === "") {
      setDescriptionErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }
    setbtnDisable(true);
    const dataArray = new FormData();
    files.forEach((file, index) => {
      dataArray.append(`file${index}`, file);
    });
    dataArray.append("priority", prior);
    dataArray.append("subject", subject);
    dataArray.append("description", description);
    dataArray.append("userid", userID);

    axiosInstance
      .post("/vozo/patient/supportdocumentupload?", dataArray)
      .then((response) => {
        setbtnDisable(false);
        setbtnDisable1(false);

        if (response.data === "Mail Failed") {
          setModalMsg("Mail Failed");
          setModalAlerShow1(true);
          setTimeout(() => setModalAlerShow1(false), 1500);
        } else {
          setModalMsg("Email Sent Successfully");
          setModalAlerShow(true);
          setTimeout(() => setModalAlerShow(false), 1500);
        }
        handleClose();
      })
      .catch((err) => {
        setModalMsg("Failed to Upload Document");
        setModalAlerShow1(true);
        setTimeout(() => setModalAlerShow1(false), 1500);
      });
  };

  useEffect(() => {
    if (NavData1 !== "") {
      setNavData1(NavData1);
    }
  }, [NavData1]);
  //-----------------------------Function End------------------------------------------------- //

  return (
    <>
      <WenoDiv
        className="flexWrapDt"
        zIndex="1040"
        position={Showcontact || showAdd1 || showAdd2 ? "inherit" : "fixed"}
        width="100%"
        textAlign="left"
      >
        <Span cursor="pointer" marginLeft="20px" onClick={handleOpenFeedback}>
          Feedback
        </Span>
        <Span cursor="pointer" marginLeft="20px" onClick={handleOpen}>
          Support
        </Span>
      </WenoDiv>
      <>
        {showSupport && (
          <ModalsendFax
            show={showSupport}
            justifyContent="center"
            onClick={handleClose}
            overflowY="auto"
            zIndex="1080"
          >
            <ModalContainer
              position="relative"
              justifyContent="center"
              borderRadius="8px"
              overflowX="revert"
              overflowY="revert"
              onClick={handleContainerClick}
            >
              {" "}
              <ModalDialog width="432px" borderRadius="8px">
                <ModalHeader
                  padding="0px 16px 0px 16px"
                  height="69px"
                  borderLess
                  justifyContent=" space-between"
                >
                  <PageTitle
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="18px"
                    marginTop="13px"
                    lineHight="24px"
                    color="#2E2E2E"
                    padding="15px 0px 0px 0px"
                    marginBottom="0px"
                  >
                    Submit a Support Ticket
                  </PageTitle>
                  <Supportclosebtn onClick={() => handleClose()}>
                    <FaTimes />
                  </Supportclosebtn>
                </ModalHeader>

                <ModalBody
                  padding="0px 16px 0px 16px"
                  height="463px"
                  overflowY="auto"
                >
                  <Div margin="15px 0px 0px 0px" className="weno_select">
                    <Label
                      fontFamily="'Open Sans',sans-serif"
                      fontSize="14px"
                      color="#2E2E2E"
                      fontWeight="400"
                      marginRight="0px"
                      style={{ display: "flex" }}
                    >
                      Priority
                    </Label>
                    <Select
                      placeholder=" Select"
                      suffixIcon={<CustomSuffixIcon />}
                      value={prior ? prior : undefined}
                      onChange={priorChange}
                      className="w-100 "
                      dropdownClassName="weno-drop"
                    >
                      <Option value="Low">Low</Option>
                      <Option value="Medium" id="prc_opt-prip_med">
                        Medium
                      </Option>
                      <Option value="High">High</Option>
                    </Select>
                  </Div>

                  <Div margin="8px 0px 0px 0px">
                    <Label
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="400"
                      marginRight="0px"
                      display="flex"
                    >
                      Subject
                    </Label>
                    <Input
                      display="flex"
                      width="400px"
                      height="36px"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      paddingTop="8px !important"
                      margin="5px 0px 0px 0px"
                      value={subject}
                      style={
                        subjectErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                      onChange={subjectChange}
                    />
                    {subjectErr && (
                      <ErrorMessage>Please Enter Subject</ErrorMessage>
                    )}
                  </Div>

                  <Div margin="8px 0px 0px 0px">
                    <Label
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="400"
                      marginRight="0px"
                      textAlign="left"
                    >
                      Description
                      <Div fontSize="12px" fontFamily="'Open Sans',sans-serif">
                        Kindly provide the details of your request, including
                        the steps to reproduce the issue.
                      </Div>
                    </Label>
                    <TextArea
                      display="flex"
                      width="400px"
                      height="100px"
                      minHeight="100px"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      resize="none"
                      paddingTop="8px !important"
                      margin="7px 0px 0px 0px"
                      onChange={descriptionChange}
                      value={description}
                      style={
                        descriptionErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                    ></TextArea>
                    {descriptionErr && (
                      <ErrorMessage>Please Enter Description</ErrorMessage>
                    )}
                  </Div>
                  <Div margin="8px 0px 0px 0px">
                    <Label
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="400"
                      marginRight="0px"
                      textAlign="left"
                      display="inherit"
                    >
                      Attachment (Optional)
                      <Div fontSize="12px" fontFamily="'Open Sans',sans-serif">
                        Kindly update any screenshots or relevant files
                      </Div>
                    </Label>
                    <Div
                      display="flex"
                      width="400px"
                      height="36px"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      paddingTop="2px !important"
                      margin="5px 0px 0px 0px"
                      style={{
                        pointerEvents: btnDisable1 ? "none" : "auto",
                        opacity: btnDisable1 ? 0.5 : 1,
                        cursor: btnDisable1 ? "not-allowed" : "pointer",
                      }}
                    >
                      <label className="filelabel ms-5 weno-label">
                        <div className="ant-upload-drag-container">
                          <p className="ant-upload-hint">
                            <span>Add file </span> or drop file here
                          </p>

                          <input
                            type="file"
                            name="patientdocument"
                            id="documentfileup"
                            className="FileUploaddoc"
                            onChange={changeimgpre}
                            accept=".png, .pdf, .jpg, .jpeg, .csv, .txt"
                            multiple
                          />
                        </div>
                      </label>
                    </Div>
                    {dragdropErr && (
                      <span className="red-col">{fileErrMsg}</span>
                    )}

                    <div className="t-a-c">
                      <div className="weno-file-list">
                        {fileNames.map((fileName, index) => (
                          <div key={index} className="weno-file-item">
                            <div className="weno-file-item-div">
                              <BsFileEarmarkText className="up-d-icon-f" />
                              <span className="file-icon-bg">{fileName}</span>
                            </div>
                            <BsXCircle
                              onClick={() => fileremove(fileName)}
                              className="can-file up-d-xcir-ic"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Div>
                </ModalBody>

                <ModalFooter1
                  padding="0px 18px 10px 24px"
                  justifyContent="right"
                  borderTop="none"
                >
                  <Button
                    type="submit"
                    borderRadius="4px"
                    border="none"
                    padding="5px 18px 5px 18px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    cursor="pointer"
                    hoverBackColor="#005FB2"
                    className="save_active"
                    onClick={handleSave}
                    disabled={btnDisable}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="Open Sans"
                    >
                      Submit
                    </Span>
                  </Button>
                </ModalFooter1>
              </ModalDialog>
            </ModalContainer>
          </ModalsendFax>
        )}
        {showFeedback && (
          <ModalsendFax
            show={showFeedback}
            justifyContent="center"
            onClick={handleCloseFeedback}
            overflowY="auto"
            zIndex="1080"
          >
            <ModalContainer
              position="relative"
              justifyContent="center"
              borderRadius="8px"
              overflowX="revert"
              overflowY="revert"
              onClick={handleContainerClick}
            >
              {" "}
              <ModalDialog width="442px" borderRadius="8px">
                <ModalHeader
                  padding="0px 16px 0px 16px"
                  height="69px"
                  borderLess
                  justifyContent="flex-end"
                >
                  <Supportclosebtn onClick={() => handleCloseFeedback()}>
                    <FaTimes />
                  </Supportclosebtn>
                </ModalHeader>

                <ModalBody padding="0px 16px 0px 16px">
                  <Div margin="15px 0px 0px 0px" className="weno_select">
                    <Label
                      fontFamily="'Open Sans',sans-serif"
                      fontSize="18px"
                      color="#2E2E2E"
                      fontWeight="600"
                      marginRight="0px"
                      lineHeight="24px"
                      padding="0 20px 0 20px"
                    >
                      How was your experience and how likely are you to
                      recommend us to a friend?
                    </Label>
                    <Div margin="5px 4px 14px 0px">
                      <StarRating
                        count={5}
                        size="40px"
                        activeColor="#ffd700"
                        inactiveColor="#d3d3d3"
                        initialRating={0}
                        onChange={ratingChanged}
                      />
                      {ratingErr && (
                        <ErrorMessage className="rat-err">
                          Please Select Rating
                        </ErrorMessage>
                      )}
                    </Div>
                  </Div>

                  <Div margin="25px 0px 0px 0px">
                    <Label
                      fontSize="18px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="600"
                      marginRight="0px"
                      lineHeight="24px"
                    >
                      {reset_rating_point === 0
                        ? "We Value Your Feedback "
                        : reset_rating_point >= 3
                        ? " We’re Glad You Love Our Product! "
                        : "How Can We Make Things Better?"}
                    </Label>
                    <TextArea
                      className="textarea-placeholder"
                      display="flex"
                      width="400px"
                      height="80px"
                      minHeight="80px"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      paddingTop="8px !important"
                      margin="16px 0px 6px 0px"
                      resize="none"
                      value={feedbackdescription}
                      onChange={feedbackdescriptionChange}
                      placeholder={
                        reset_rating_point === 0
                          ? "Please type here"
                          : reset_rating_point >= 3
                          ? "Share your thoughts"
                          : "Let us know what didn’t work for you"
                      }
                    ></TextArea>
                  </Div>
                </ModalBody>

                <ModalFooter1
                  padding="0px 18px 16px 24px"
                  justifyContent="right"
                  borderTop="none"
                >
                  <Button
                    type="submit"
                    borderRadius="4px"
                    border="none"
                    padding="5px 36px 5px 36px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    cursor="pointer"
                    hoverBackColor="#005FB2"
                    className="save_active"
                    onClick={handlefeedbackSave}
                    disabled={btnDisable2}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="Open Sans"
                    >
                      Submit
                    </Span>
                  </Button>
                </ModalFooter1>
              </ModalDialog>
            </ModalContainer>
          </ModalsendFax>
        )}
        {ModalAlerShow && (
          <Modal
            className="alert-popup-message"
            show={ModalAlerShow}
            onHide={modClose}
          >
            <div className="alert msg alert-success" role="alert">
              <BsCheckCircle className="icons-bs" />
              {ModalMsg}
            </div>
          </Modal>
        )}
        {ModalAlerShow1 && (
          <Modal
            className="alert-popup-message"
            show={ModalAlerShow1}
            onHide={modClose1}
          >
            <div class="alert msg alert-danger" role="alert">
              <IoCloseSharp className="icons-bs erx_modal_icon" />
              <span className="f-s-20">{ModalMsg}</span>
            </div>
          </Modal>
        )}
      </>
    </>
  );
}

export default WenoSupport;
