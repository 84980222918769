import { takeLatest } from "redux-saga/effects";
import {
  getProviderData,
  startBookSchedule,
} from "../../StateManagement/Reducers/ScheduleWidgetState";
import { providerListWorker, saveScheduleWorker } from "./WidgetSagaWorker";

export function* providerListSaga() {
  yield takeLatest(getProviderData.type, providerListWorker);
}

export function* bookScheduleSaga() {
  yield takeLatest(startBookSchedule.type, saveScheduleWorker);
}
