import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BillingBlock,
  Div,
  Image,
  Link,
  Text,
} from "../../../StyledComponents";
import redStar from "../../../assets/images/Star_red.png";
import greenStar from "../../../assets/images/Star_green.png";
import exclamation from "../../../assets/images/exclamation.png";
import verifiedtick from "../../../assets/images/check-outline.png";
import { ResendButton } from "../OtpVerification/style";
import { BsArrowLeft } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import {
  getTemporaryRes,
  setTemporaryEmail,
  setTemporaryOtp,
  setTemporaryRes,
} from "../../../StateManagement/Reducers/SubscriptionRenewalState";
import MessageModal from "../../../components/login/ForgotPassword/MessageModal";
import {
  globalColor,
  globalFont,
} from "../../../components/utils/RepeatFunctions/Schedule";

const VerificationDone = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const dispatch = useDispatch();
  let history = useHistory();

  const [btndis, setbtndis] = useState(false);
  const [timer, setTimer] = useState(0);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const TemporaryEmail = useSelector(
    (state) => state.SubsRenewal?.TemporaryEmail
  );
  const TemporaryRes = useSelector((state) => state.SubsRenewal?.TemporaryRes);
  const Reactiverecover = useSelector(
    (state) => state.SubsRenewal?.ReactiveRecover
  );
  const TemporaryOtp = useSelector((state) => state.SubsRenewal?.TemporaryOtp);

  var user_id = Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //
  const backToLogin = () => {
    dispatch(setTemporaryOtp(""));
    dispatch(setTemporaryEmail(""));
    localStorage.clear();
    localStorage.setItem("logout", true);
    window.location.href = "/";
  };

  const clickhere = () => {
    if (TemporaryOtp === "Success") {
      let article = {
        email: TemporaryEmail.email_id,
        user_id: user_id,
      };
      dispatch(getTemporaryRes(article));
      // dispatch(setTemporaryOtp(""));
      setbtndis(true);
    } else {
      history.push("/otpverification");
      localStorage.setItem("current_verification_timer", timer);
    }
  };
  // ------------------------------- Functions End--------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    // Resume the timer from local storage
    const storedTimer = localStorage.getItem("current_verification_timer");
    setTimer(storedTimer ? parseInt(storedTimer, 10) : 0);
  }, []);

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  useEffect(() => {
    if (TemporaryRes.status === "Success") {
      setShow1(true);
      setModalMsg("Mail sent Successfully");
      setTimeout(() => {
        setShow1(false);
      }, 2000);

      setbtndis(false);
      dispatch(setTemporaryRes({}));
    } else if (TemporaryRes.status === "Failure") {
      if (TemporaryRes.output === "Email sent failed") {
        setShow2(true);
        setModalMsg("Mail not sent  Successfully");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis(false);
        dispatch(setTemporaryRes({}));
      } else if (TemporaryRes.output === "Invalid Email Id") {
        setShow2(true);
        setModalMsg("Invalid Email Id");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis(false);
        dispatch(setTemporaryRes({}));
      } else if (TemporaryRes.output === "Email Not Found") {
        setShow2(true);
        setModalMsg("Email Not Found");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis(false);
        dispatch(setTemporaryRes({}));
      }
    }
  }, [TemporaryRes]);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        background=" rgba(240, 244, 247, 1)"
      >
        <Div
          background="rgba(255, 255, 255, 1)"
          textAlign="center"
          borderRadius="8px"
          boxShadow="rgba(196, 203, 214, 0.1) 0px 6px 58px"
          position="relative"
          padding="16px 36px 25px 36px"
        >
          <Div flexCenterAll borderTlRadius="8px" borderTrRadius="8px">
            {TemporaryOtp === "Success" ? (
              <>
                <Image
                  src={greenStar}
                  alt="failed"
                  width="64px"
                  height="64px"
                />
                <Image src={verifiedtick} alt="i" position="absolute" />
              </>
            ) : (
              <>
                <Image src={redStar} alt="failed" width="64px" height="64px" />
                <Image src={exclamation} alt="i" position="absolute" />
              </>
            )}
          </Div>
          <Text
            fontSize="18px"
            fontWeight="600"
            fontFamily={globalFont}
            lineHeight="24.51px"
            color="rgba(46, 46, 46, 0.75)"
            marginBottom="16px"
            marginTop="8px"
          >
            {TemporaryOtp === "Success"
              ? "Verified Successfully"
              : "Invalid Code"}
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            fontFamily={globalFont}
            lineHeight="19.07px"
            color="rgba(46, 46, 46, 0.75)"
            textAlign="left"
            width="429px"
          >
            {TemporaryOtp === "Success" && Reactiverecover === "reactive_acct"
              ? "We have generated a temporary password for your account, kindly check your email."
              : TemporaryOtp === "Success" && Reactiverecover === "recover_data"
              ? "Your account data will be transferred in an XLS & CSV format, within a day. Kindly check your email after sometime."
              : "The verification code which you have entered is Invalid, check the code before you enter."}
          </Text>
          <Text
            textAlign="center"
            marginTop="23.9px"
            fontSize="14px"
            fontWeight="600"
            lineHeight="19.07px"
            color="rgba(46, 46, 46, 0.5)"
            fontFamily={globalFont}
          >
            {TemporaryOtp === "Success" && Reactiverecover === "reactive_acct"
              ? "Didn’t receive the email?"
              : TemporaryOtp === "Success" && Reactiverecover === "recover_data"
              ? ""
              : "To go back to verification page,"}
            {TemporaryOtp === "Success" &&
            Reactiverecover === "reactive_acct" ? (
              <ResendButton
                disabled={btndis}
                cursor={btndis ? "not-allowed" : "pointer"}
                onClick={clickhere}
                fontSize="14px"
                fontWeight="600"
              >
                &nbsp;Click to resend
              </ResendButton>
            ) : TemporaryOtp === "Success" &&
              Reactiverecover === "recover_data" ? (
              ""
            ) : (
              <ResendButton disabled={btndis} onClick={clickhere}>
                &nbsp;Click here
              </ResendButton>
            )}
          </Text>
          <Link onClick={backToLogin}>
            <Div
              flexCenterAll
              color={`${globalColor} !important`}
              fontSize="16px"
            >
              <BillingBlock
                marginRight="8px"
                marginLeft="unset"
                color={`${globalColor} !important`}
              >
                <BsArrowLeft />
              </BillingBlock>
              <BillingBlock
                color={`${globalColor} !important`}
                marginLeft="unset"
              >
                Back to Login
              </BillingBlock>
            </Div>
          </Link>
        </Div>
      </Div>
      <MessageModal
        active={show1}
        color="success"
        onHide={() => setShow1(false)}
      >
        {modalMsg}
      </MessageModal>
      <MessageModal
        active={show2}
        color="danger"
        onHide={() => setShow2(false)}
      >
        {modalMsg}
      </MessageModal>
    </>
  );
};

export default VerificationDone;
