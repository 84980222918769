import styled from "styled-components";
import { Select } from "antd";
import { globalColor } from "../../../components/utils/RepeatFunctions/Schedule";

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  position: ${(p) => p.position && p.position};
`;
export const Span = styled.span`
  margin: ${(p) => p.margin && p.margin};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const Button = styled.button`
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  position: ${(p) => p.position && p.position};
  padding: ${(p) => p.padding && p.padding};
  ${(p) =>
    p.lock &&
    `svg {
     margin-top:-3px;
     margin-right:1px;
    }
    `}

  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  &:active {
    background-color: ${(p) => p.ActiveBackColor && p.ActiveBackColor};
    border: ${(p) => p.border && p.border};
  }
`;
export const SelectAnt = styled(Select)`
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.marginright && p.marginright};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  position: ${(p) => p.position && p.position};
  margin-top: ${(p) => p.margintop && p.margintop};

  &.ant-select-single .ant-select-selector :hover {
    border-color: #d9d9d9 !important;
  }
  &.ant-select-single .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    height: 36px;
  }
  &.ant-select-single .ant-select-arrow {
    top: 0% !important;
    margin-top: 12px !important;
  }

  &.ant-select-multiple .ant-select-arrow {
    top: 0% !important;
    margin-top: 10px !important;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    flex: initial !important;
  }

  &.ant-select-multiple .ant-select-selection-item {
    background-color: #ebf3ff !important;
    color: ${globalColor} !important;
  }

  &.ant-select-multiple .ant-select-selection-item-content {
    font-size: 12px !important;
    font-weight: 500;
  }

  &.ant-select-multiple .ant-select-selection-item-remove {
    color: ${globalColor} !important;
    font-weight: 500;
    font-size: 8px;
  }

  &.ant-select-multiple .ant-select-selector {
    border-radius: 4px !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  &.ant-select-multiple .ant-select-selector:hover {
    border-color: #d9d9d9 !important;
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    color: #718096 !important;
  }
`;

export const Imagecalendar = styled.img`
  position: absolute;
  top: ${(p) => (p.top ? p.top : "20px")};
  right: ${(p) => (p.right ? p.right : "163px")};
  width: 16px;
  height: 16px;
  object-fit: contain;
  cursor: pointer;
`;
