import React, { useState, memo } from "react";
import Warning from "../popups/Warning";

const UpgradeRequired = ({
  title,
  description,
  boxContent,
  handlecloseUpgrade,
  bgChange,
  setRemoveAccess,
  fromWhere,
}) => {
  const [showWarning, setShowWarning] = useState(true);
  const handleShow = () => {
    setShowWarning(false);
    setRemoveAccess &&
      setTimeout(() => {
        setRemoveAccess(false);
      }, 1);
    if (typeof handlecloseUpgrade === "function") handlecloseUpgrade();
  };

  return (
    <div
      className={
        fromWhere && fromWhere === "reminder_report"
          ? ""
          : "right-block m-l-250"
      }
    >
      <div id="demographics" className="vozo_padding">
        <Warning
          setRemoveAccess={setRemoveAccess}
          show={showWarning}
          onHide={handleShow}
          title={title}
          description={description}
          boxContent={boxContent}
          bgchange={bgChange && bgChange}
        />
      </div>
    </div>
  );
};

export default memo(UpgradeRequired);
