import { createSlice } from "@reduxjs/toolkit";

export const PreLoginSlice = createSlice({
  name: "PreLogin",
  initialState: {
    sendOtp: false,
    EmailResp: {},
    showLoading: false,
    btnDisable: false,
    resendSucs: false,
  },
  reducers: {
    GetEmailRes: (state, action) => {
      if (action.payload.mode === "initial") {
        state.showLoading = true;
      }
      state.btnDisable = true;
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
      state.sendOtp = false;
      state.showLoading = false;
      state.btnDisable = false;

      if (action.payload.status === "Success") {
        state.otp = action.payload.otp;
      } else if (action.payload.status === "resend") {
        state.resendSucs = true;
      }
    },
    ResendSucsFalse: (state) => {
      state.resendSucs = false;
      return state;
    },
    setVerifyUserEmail: (state, action) => {
      localStorage.setItem("verified_email", action.payload.email);
      state.sendOtp = true;
    },
    setResendDis: (state, action) => {
      state.btnDisable = action.payload;
    },
  },
});

export const {
  setVerifyUserEmail,
  GetEmailRes,
  setEmailRes,
  ResendSucsFalse,
  setResendDis,
} = PreLoginSlice.actions;

export default PreLoginSlice.reducer;
