import React, { memo } from "react";
import { Modal, Button } from "react-bootstrap";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import axiosInstance from "../../axios/axios";

const Warning = (props) => {
  var uid = Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  const generateRandomPassword = (length = 10) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    return Array.from(
      { length },
      () => charset[Math.floor(Math.random() * charset.length)]
    ).join("");
  };
  const launchWebsite = () => {
    axiosInstance
      .get("/vozo/loginemail?uid=" + uid)
      .then((res) => {
        let data = Decrypt_Value(res.data, "vozo");
        const randomPassword = generateRandomPassword();
        const EncryptrandomPassword = Encrypt_Value(randomPassword, "vozo");
        const url = new URL("https://vozohealth.com/pricing");
        url.searchParams.append("token", EncryptrandomPassword);
        url.searchParams.append("email", data["email"]);

        axiosInstance
          .put("/vozo/feedbackloginpass?uid=" + uid, EncryptrandomPassword)
          .then((res) => {
            let data = Decrypt_Value(res.data, "vozo");
          })
          .catch((err) => {
            console.error("there was an error while updating login status");
          });
        window.open(url.toString(), "_blank");
      })
      .catch((err) => {
        console.error("there was an error while updating login status");
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upgrade-req-modal popup_centre"
      style={props.bgchange ? { backgroundColor: "rgba(0,0,0,.25)" } : {}}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upgrade Required</Modal.Title>
      </Modal.Header>

      <Modal.Body className="upg-req-margin">
        <div className="upg-req-one">
          <div className="req-1-line1">{props.title}</div>
          <div className="req-1-line2">{props.description}</div>
          <div className="req-1-line3">{props.boxContent}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right w-100">
          <Button
            className="upg-req-modal"
            variant="light"
            onClick={props.onHide}
          >
            Cancel
          </Button>
          <Button
            className="upg-req-btn"
            variant="primary"
            onClick={launchWebsite}
          >
            Upgrade
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(Warning);
